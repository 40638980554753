/*
    Bootstrap
*/
body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5;
    margin: 0;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

h6, h5, h4, h3, h2, h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

/*
    Fix divider line
*/
.p-divider-solid.p-divider-horizontal:before {
    border-top-style: solid !important
}

/*
    Better toasts visibility
*/
.p-toast-message {
    border-width: 1px 1px 1px 6px !important;
}

.p-toast-detail {
    margin: 0.25rem 0 0 0 !important;
}

/*
    Bootstrap-like fluid container
*/
.p-container,
.p-container-fluid,
.p-container-sm,
.p-container-md,
.p-container-lg,
.p-container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .p-container, .p-container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .p-container, .p-container-sm, .p-container-md {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .p-container, .p-container-sm, .p-container-md, .p-container-lg {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .p-container, .p-container-sm, .p-container-md, .p-container-lg, .p-container-xl {
        max-width: 1140px;
    }
}

/*
    Allow to display text in multiple line
*/
.white-space-preline {
    white-space: pre-line !important;
}

/*
    Fix text align in columns with long text
*/
@media screen and (max-width: 960px) {
    .p-datatable[null] .p-datatable-tbody > tr > td > .p-column-title {
        text-align: left !important;
        margin-right: 0.5rem;
    }

    .p-datatable[null] .p-datatable-tbody > tr > td {
        text-align: right !important;
    }
}

@media screen and (min-width: 961px) {
    .p-datatable[null] .p-datatable-tbody > tr > td {
        text-align: left !important;
    }
}