.p-admin-card .p-admin-card-header {
    padding: 1.25rem 1.25rem 0 1.25rem;
}

.p-admin-card .p-admin-card-divider {
    padding: 0 1.25rem 0 1.25rem;
}

.p-admin-card .p-card-body {
    padding-top: 0;
}

.p-admin-card .p-card-content {
    padding: 0;
}
